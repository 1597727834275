<script setup lang="ts" generic="T extends Record<string, any>">
import { Combobox, ComboboxButton, ComboboxInput, ComboboxOption } from '@headlessui/vue'
import { ChevronUpDownIcon } from '@heroicons/vue/20/solid'
import { useVirtualizer } from '@tanstack/vue-virtual'

const props = withDefaults(defineProps<{
  modelValue: T | null,
  items: T[],
  displayValue?: (item: T) => string,
  filterFunction?: (item: T, query: string) => boolean,
  estimateSize?: number,
  placeholder?: string,
  disabled?: boolean,
  theme?: 'light' | 'primary',
  hasPrefix?: boolean,
  hasSuffix?: boolean,
}>(), {
  displayValue: (item: T) => String(item ?? ''),
  filterFunction: (item: T, query: string): boolean => {
    const itemStr = String(item).toLowerCase()
    return itemStr.includes(query.toLowerCase())
  },
  estimateSize: 35,
  placeholder: 'Rechercher...',
  disabled: false,
  theme: 'light',
  hasPrefix: false,
  hasSuffix: true,
})

const emit = defineEmits<{
  'update:modelValue': [value: T | null]
}>()

const query = ref('')
const parentRef = ref<HTMLElement | null>(null)

// Handling mobile touch events
const touchStartY = ref<number | null>(null)
const handleTouchStart = (e: TouchEvent) => {
  e.preventDefault()
  touchStartY.value = e.touches[0].clientY
}
const handleTouchEnd = (e: TouchEvent, item: T) => {
  e.preventDefault()
  if (touchStartY.value === null) return
  
  const touchEndY = e.changedTouches[0].clientY
  const touchDiff = Math.abs(touchEndY - touchStartY.value)

  // If movement is less than 10px, consider it a tap
  if (touchDiff < 10) {
    selectedItem.value = item
  }
  
  touchStartY.value = null
}

const filteredItems = computed(() => {
  return query.value === ''
    ? props.items
    : props.items.filter(item => props.filterFunction(item, query.value))
})

const hasResults = computed(() => filteredItems.value.length > 0)

const rowVirtualizer = computed(() => useVirtualizer({
  count: props.items.length,
  getScrollElement: () => parentRef.value,
  estimateSize: () => props.estimateSize,
  overscan: 5,
}))

// Update the template to use ref callback
const virtualRows = computed(() => rowVirtualizer.value.value.getVirtualItems())
// const totalSize = computed(() => rowVirtualizer.value.getTotalSize())

const selectedItem = computed({
  get: () => props.modelValue,
  set: (value) => emit('update:modelValue', value)
})
</script>

<template>
  <div>
    <Combobox v-model="selectedItem" v-slot="{ open }" nullable :disabled="disabled">
      <div class="relative mt-1">
        <div 
          class="relative w-full overflow-hidden rounded-lg text-left border focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm"
          :class="[
            { 'cursor-default': !disabled, 'cursor-not-allowed opacity-75': disabled },
            theme === 'light' ? 'bg-white border-gray-300' : 'bg-sky-blue-700 border-sky-blue-400'
          ]"
        >
          <ComboboxButton as="div" class="w-full">
            <div class="flex items-center">
              <div v-if="hasPrefix" class="flex-shrink-0 pl-2.5 cursor-pointer">
                <slot name="prefix" />
              </div>
              <ComboboxInput
                class="w-full border-none py-2 text-sm leading-5 focus:ring-0"
                :class="[
                  { 'cursor-not-allowed': disabled },
                  hasPrefix ? 'pl-2' : 'pl-3',
                  hasSuffix ? 'pr-10' : 'pr-2',
                  theme === 'light' ? 'text-gray-900 bg-white placeholder-gray-400' : 'text-white bg-sky-blue-700 placeholder-white'
                ]"
                :displayValue="(item: any) => props.displayValue(item)"
                @change="query = $event.target.value"
                @blur="query = ''"
                :placeholder="props.placeholder"
                :disabled="disabled"
              />
            </div>
          </ComboboxButton>
          <ComboboxButton
            v-if="hasSuffix"
            class="absolute inset-y-0 right-0 flex items-center pr-2"
            :class="{ 'cursor-not-allowed': disabled }"
          >
            <ChevronUpDownIcon 
              class="h-5 w-5" 
              :class="[
                { 'opacity-50': disabled },
                theme === 'light' ? 'text-gray-400' : 'text-sky-blue-300'
              ]" 
              aria-hidden="true" 
            />
          </ComboboxButton>
        </div>

        <div 
          v-show="open && hasResults"
          ref="parentRef"
          style="overflow-anchor: none;"
          class="absolute mt-1 max-h-60 w-full overflow-auto rounded-md py-1 text-base shadow-lg focus:outline-none sm:text-sm"
          :class="[
            'bg-white ring-1 ring-black ring-opacity-5'
          ]"
        >
          <div
            :style="{
              height: `${filteredItems.length * props.estimateSize}px`,
              width: '100%',
              position: 'relative'
            }"
          >
            <div
              v-for="virtualRow in virtualRows.filter(row => filteredItems[row.index])"
              :key="virtualRow.index"
              :style="{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: `${virtualRow.size}px`,
                transform: `translateY(${virtualRow.start}px)`
              }"
              :data-index="virtualRow.index"
              @touchstart="handleTouchStart($event)"
              @touchend="handleTouchEnd($event, filteredItems[virtualRow.index])"
            >
              <ComboboxOption
                :value="filteredItems[virtualRow.index]"
                as="div"
                class="h-full"
                v-slot="{ active, selected }"
              >
                <slot
                  name="option"
                  :item="filteredItems[virtualRow.index]"
                  :is-selected="selected"
                  :is-active="active"
                >
                  <div
                    class="relative cursor-default select-none py-2 px-4"
                    :class="{
                      'bg-sky-blue-400 text-white': active,
                      'text-gray-900': !active,
                    }"
                  >
                    {{ props.displayValue(filteredItems[virtualRow.index]) }}
                  </div>
                </slot>
              </ComboboxOption>
            </div>
          </div>
        </div>

        <div
          v-if="open && !hasResults && query"
          class="absolute mt-1 w-full rounded-md py-1 text-base shadow-lg focus:outline-none sm:text-sm"
          :class="[
            'bg-white ring-1 ring-black ring-opacity-5'
          ]"
        >
          <div 
            class="relative cursor-default select-none py-2 px-4"
            :class="'text-gray-700'"
          >
            Aucun résultat trouvé
          </div>
        </div>
      </div>
    </Combobox>
  </div>
</template> 